import { calculateTypeScale } from 'utopia-core';

import { MEDIA_QUERIES } from '@/utils/theme/sharedStyles';

export function flattenObjectToCssVars(
  value: unknown,
  prefix: string = ''
): string {
  if (value === null) return '';
  if (typeof value === 'function') return '';

  // filter out css strings that aren't just a value
  if (typeof value === 'string' && (value.includes(';') || value.includes(':')))
    return '';

  if (Array.isArray(value)) {
    return value.map(entry => flattenObjectToCssVars(entry, prefix)).join('');
  }

  if (typeof value === 'object') {
    return Object.entries(value)
      .map(([key, val]) => flattenObjectToCssVars(val, `${prefix}-${key}`))
      .join('');
  }

  return `-${prefix}: ${value};\n`;
}

export function generateFluidFontSize(min: number, max: number) {
  const scale = calculateTypeScale({
    minWidth: MEDIA_QUERIES.MOBILE,
    maxWidth: MEDIA_QUERIES.EXTRA_LARGE,
    minFontSize: min,
    maxFontSize: max,
    // these are pretty irrelevant since we don't
    // really have a consistent type scale in our
    // existing type system
    minTypeScale: 1,
    maxTypeScale: 1
  });

  return scale.find(step => step.step === 0)!.clamp;
}
