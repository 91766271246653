import { mix, rgba } from 'polished';

const base = {
  black: '#01011b',
  white: '#ffffff',
  obsidian: '#14141C',
  roseQuartz: '#F5C0C0',
  amethyst: '#A477B2',
  jade: '#5CB198',
  citrine: '#CDA849',
  opal: '#FBF9F9',
  violetTopaz: '#5F509D',
  cement: '#717A94'
};

const shades = {
  '100': 1,
  '200': 0.026,
  '300': 0.056,
  '400': 0.077,
  '500': 0.103,
  '600': 0.129,
  '700': 0.172,
  '800': 0.249,
  '900': 0.386,
  '1000': 0.446,
  '1100': 0.592,
  '1200': 0.923
};

type ValueRecordToColorRecord<
  P extends string,
  R extends Record<string | number, string | number>
> = {
  [K in keyof R as K extends string ? `${P}${K}` : never]: string;
};

const fg = Object.entries(shades).reduce(
  (acc, [key, val]) => {
    acc[`fg${key}`] = mix(val, base.roseQuartz, base.obsidian);
    return acc;
  },
  {} as Record<string, string>
) as ValueRecordToColorRecord<'fg', typeof shades>;

const fgA = Object.entries(shades).reduce(
  (acc, [key, val]) => {
    acc[`fgA${key}`] = rgba(base.roseQuartz, val);
    return acc;
  },
  {} as Record<string, string>
) as ValueRecordToColorRecord<'fgA', typeof shades>;

// @TODO light and dark modes
export const palette = {
  ...base,
  ...fg,
  ...fgA
};
